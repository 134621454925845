import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { alpha, styled, useTheme } from '@mui/material/styles';
import {
    Box,
    Card,
    CardContent,
    Typography,
    CardMedia,
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Container,
    Divider,
    MenuItem,
    Drawer,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import {
    MenuIcon,
    CloseRoundedIcon,
} from "../../utils/iconList";

import mLogo from "../../assets/images/mitsun-logo.jpg";
import SitemarkIcon from './SitemarkIcon';
import ColorModeIconDropdown from '../.././theme/ColorModeIconDropdown';

const StyledToolbar = styled(Toolbar)(({ theme }) => {
    return ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexShrink: 0,
        borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
        backdropFilter: 'blur(24px)',
        border: '1px solid',
        borderColor: (theme.vars || theme).palette.divider,
        backgroundColor: 'none',
        // backgroundColor: theme.vars
        //     ? `rgba(${theme.vars.palette.background.defaultChannel} / 0.4)`
        //     : alpha(theme.palette.background.default, 0.4),
        boxShadow: (theme.vars || theme).shadows[1],
        padding: '8px 12px',
    })
});

const AppAppBar = () => {
    const [open, setOpen] = React.useState(false);
    let navigate = useNavigate();
    const theme = useTheme();
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleProd = () => {
        let clsList = document.getElementById("hoverMenu").classList;
        if (clsList && clsList.length) {
            clsList.remove('show');
        }
        navigate("/products");
    }

    // For the product menus
    const cardData = [
        {
            img: 'https://picsum.photos/800/450?random=3',
            tag: 'Design',
            title: 'Designing for the future',
            description:
                'Stay ahead of the curve with the latest design trends and insights. Our design team shares their expertise on creating intuitive and visually stunning user experiences.',
            authors: [{ name: 'Kate Morrison', avatar: '/static/images/avatar/7.jpg' }],
        },
        {
            img: 'https://picsum.photos/800/450?random=4',
            tag: 'Company',
            title: "Designing for the future",
            description:
                "Take a look at our company's journey and the milestones we've achieved along the way. From humble beginnings to industry leader, discover our story of growth and success.",
            authors: [{ name: 'Cindy Baker', avatar: '/static/images/avatar/3.jpg' }],
        },
        {
            img: 'https://picsum.photos/800/450?random=6',
            tag: 'Product',
            title: 'Designing for the future',
            description:
                'Our recent product updates are designed to help you maximize efficiency and achieve more. Get a detailed overview of the new features and improvements that can elevate your workflow.',
            authors: [{ name: 'Travis Howard', avatar: '/static/images/avatar/2.jpg' }],
        },
        {
            img: 'https://picsum.photos/800/450?random=6',
            tag: 'Product',
            title: 'Designing for the future',
            description:
                'Our recent product updates are designed to help you maximize efficiency and achieve more. Get a detailed overview of the new features and improvements that can elevate your workflow.',
            authors: [{ name: 'Travis Howard', avatar: '/static/images/avatar/2.jpg' }],
        },
    ];

    return (
        <AppBar
            position="fixed"
            enableColorOnDark
            sx={{
                boxShadow: 0,
                bgcolor: 'transparent',
                backgroundImage: 'none',
                mt: 'calc(var(--template-frame-height, 0px) + 28px)',
            }}
        >
            <Container maxWidth="lg">
                <StyledToolbar variant="dense" disableGutters>
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
                        <SitemarkIcon
                            imageUrl={mLogo}
                            altText="Custom Logo"
                            sx={{ height: 50, width: 150, cursor: 'pointer', }}
                            handleClick={() => navigate("/dashboard")}
                        />
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <div className="dropdown">
                                <Button variant="text" color="info" size="small" onClick={() => { handleProd() }}
                                    data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                    Products
                                </Button>
                                <div id='hoverMenu' className="dropdown-menu" style={{ minWidth: '900px' }}>
                                    <Box sx={{ width: '100%', paddingLeft: 1, paddingRight: 1 }}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                                            {
                                                cardData && cardData.length && cardData.map((item, index) =>
                                                    <Grid size={4} sx={{ cursor: 'pointer' }} key={index}>
                                                        <Card sx={{ display: 'flex' }}>
                                                            <CardMedia
                                                                component="img"
                                                                sx={{ width: 100, height: 100, borderRadius: 1 }}
                                                                image={'https://cdn.pixabay.com/photo/2023/10/01/14/40/medicine-8287535_1280.jpg'}
                                                                alt="Tab1"
                                                            />
                                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                                    <Typography component="div" variant="h5">
                                                                        {item.title}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        component="div"
                                                                        sx={{ color: 'text.secondary' }}
                                                                    >
                                                                        Mac Miller
                                                                    </Typography>
                                                                </CardContent>
                                                            </Box>
                                                        </Card>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                            <Button variant="text" color="info" size="small" onClick={() => navigate("/testimonials")}>
                                Testimonials
                            </Button>
                            <Button variant="text" color="info" size="small" onClick={() => navigate("/about-us")}>
                                About Us
                            </Button>
                            <Button variant="text" color="info" size="small" onClick={() => navigate("/contact-us")}>
                                Contact Us
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            gap: 1,
                            alignItems: 'center',
                        }}
                    >
                        {/* <ColorModeIconDropdown /> */}
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1 }}>
                        {/* <ColorModeIconDropdown size="medium" /> */}
                        <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="top"
                            open={open}
                            onClose={toggleDrawer(false)}
                            PaperProps={{
                                sx: {
                                    top: 'var(--template-frame-height, 0px)',
                                },
                            }}
                        >
                            <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <IconButton onClick={toggleDrawer(false)}>
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </Box>
                                <MenuItem>Products</MenuItem>
                                <MenuItem>Testimonials</MenuItem>
                                <MenuItem>About Us</MenuItem>
                                <MenuItem>Contact Us</MenuItem>
                                <Divider sx={{ my: 3 }} />
                                {/* <MenuItem>
                                    <Button color="primary" variant="contained" fullWidth>
                                        Sign up
                                    </Button>
                                </MenuItem>
                                <MenuItem>
                                    <Button color="primary" variant="outlined" fullWidth>
                                        Sign in
                                    </Button>
                                </MenuItem> */}
                            </Box>
                        </Drawer>
                    </Box>
                </StyledToolbar>
            </Container>
        </AppBar>
    );
}

export default AppAppBar;
