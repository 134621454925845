import { Routes, Route } from "react-router-dom";
import usePageTitle from "../utils/hooks/usePageTitle";

import UserLayout from "../layouts/UserLayout";
import Page404 from "../components/common/Page404";

const ContactUs = () => <h1>ContactUs (Public)</h1>;
const Dashboard = () => <h1>
  User Dashboard
  Dashboard (Public)
</h1>;

const Products = () => {

  // For the product menus
  const cardData = [
    {
      img: 'https://picsum.photos/800/450?random=3',
      tag: 'Design',
      title: 'Designing for the future: trends and insights',
      description:
        'Stay ahead of the curve with the latest design trends and insights. Our design team shares their expertise on creating intuitive and visually stunning user experiences.',
      authors: [{ name: 'Kate Morrison', avatar: '/static/images/avatar/7.jpg' }],
    },
    {
      img: 'https://picsum.photos/800/450?random=4',
      tag: 'Company',
      title: "Our company's journey: milestones and achievements",
      description:
        "Take a look at our company's journey and the milestones we've achieved along the way. From humble beginnings to industry leader, discover our story of growth and success.",
      authors: [{ name: 'Cindy Baker', avatar: '/static/images/avatar/3.jpg' }],
    },
    {
      img: 'https://picsum.photos/800/450?random=6',
      tag: 'Product',
      title: 'Maximizing efficiency with our latest product updates',
      description:
        'Our recent product updates are designed to help you maximize efficiency and achieve more. Get a detailed overview of the new features and improvements that can elevate your workflow.',
      authors: [{ name: 'Travis Howard', avatar: '/static/images/avatar/2.jpg' }],
    },
  ];
  return (
    <>
      <h1>Products (Public)</h1>
    </>
  );
};
const AboutUs = () => <h1>About Us (Public)</h1>;
const Testimonials = () => <h1>Testimonials (Public)</h1>;

const UserRoutes = () => {
  const titleMap = {
    "/": "User Dashboard",
    "/dashboard": "User Dashboard",
    "/products": "Our Products",
    "/contact-us": "Contact Us",
    "/about-us": "About Us",
  };
  usePageTitle(titleMap);

  return (
    <Routes>
      <Route path="/" element={<UserLayout />}>
        <Route path="" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/testimonials" element={<Testimonials />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}

export default UserRoutes;
