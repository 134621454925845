import * as React from 'react';
import { Outlet } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import AppAppBar from '../user/layout/AppAppBar';
import MainContent from '../user/layout/MainContent';
import Latest from '../user/layout/Latest';
import Footer from '../user/layout/Footer';
import AppTheme from '../theme/AppTheme';

const UserLayout = (props) => {
    return (
        <>
            <AppTheme {...props} disableCustomTheme={false}>
                <CssBaseline enableColorScheme />

                <AppAppBar />
                <Container
                    maxWidth="lg"
                    component="main"
                    sx={{ display: 'flex', flexDirection: 'column', my: 16, gap: 4 }}
                >
                    {/* <MainContent />
                    <Latest /> */}
                    <Outlet />
                </Container>
                <Footer />
            </AppTheme>
            {/* Topbar goes here...!
            <main className="admin-main">
                <Outlet />
            </main> */}
        </>
    )
}

export default UserLayout;
