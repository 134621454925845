
const drawerMenu = [
    { mText: "Dashboard", mKey: "dashboard", rPath: "/admin/dashboard", mIcon: "", },
    { mText: "Products", mKey: "products", rPath: "/admin/products", mIcon: "", },
    { mText: "Reports", mKey: "reports", rPath: "/admin/reports", mIcon: "", },
    { mText: "Settings", mKey: "settings", rPath: "/admin/settings", mIcon: "", },
];

export {
    drawerMenu,
}
